<template>
  <el-container style="height: 100%">
    <!-- 左侧 -->
    <el-aside :width="fold ? 'auto' : '170px'">
      <!-- 深蓝色背景  #00161d  color #fff -->
      <el-menu
        class="main_menu"
        @select="select"
        :collapse="fold"
        :unique-opened="true"
        :collapse-transition="false"
        :default-active="currentMenu"
      >
        <!-- 左侧logo -->
        <div class="logo">
          <img src="../../res/img/logo.png" @click="Jump" />
        </div>

        <!-- 左侧菜单 -->
        <el-submenu
          v-for="(group, idx) in menu"
          :key="idx"
          :index="idx.toString()"
        >
          <!-- 菜单组 -->
          <template slot="title">
            <i
              class="icon"
              :class="group.icon ? group.icon : 'el-icon-menu'"
            ></i>
            &nbsp;&nbsp;
            <span slot="title">{{ group.name }}</span>
          </template>

          <!-- 单个菜单 -->
          <el-menu-item
            v-for="(item, index) in group.children"
            :key="index"
            :index="idx + '-' + index"
          >
            {{ item.name }}
            <span v-if="item.jb != 0">
              <el-badge :value="item.jb" class="item" id="badge"> </el-badge>
            </span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>

    <!-- 右边 -->
    <el-container class="right_container">
      <!-- 头部一行 -->
      <el-header class="right_header" style="height: 50px">
        <div class="left" @click="menu_toggle">
          <i
            :class="
              'text-xxl ' + (fold ? 'el-icon-s-unfold' : 'el-icon-s-fold')
            "
          ></i>
        </div>
        <div class="right">
          <div class="text">
            {{ user_info.company_name }} &nbsp;&nbsp;&nbsp; {{ user_info.name }}
          </div>
          <div class="el-icon-switch-button logout_icon" @click="logout"></div>
        </div>
      </el-header>
      <!-- 内容区域 -->
      <el-main class="container">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  data() {
    return {
      timer: "",
      driver: "", // 司机
      vehicle: "", //车辆
      Waybill: "", //运单
      bank: "", //银行卡

      //菜单是否折叠
      title: "有待审核得运单",
      fold: false,

      //选中菜单
      currentMenu: "",

      //菜单数据
      menu: [],

      //用户数据
      user_info: {},
    };
  },
  created() {
    this.user_info = this.$my.cache.get("user_info");
    this.menu_init();
    this.fold_init();
    this.jb_update();
    this.init();
  },
  methods: {
    	Jump(){
				// logo点击打开新页面
				var test = window.location.host
				window.open("https://"+test);
			},
    // 消息弹窗
    message_Popup() {
      Notification.requestPermission((status) => {
        if (status === "granted") {
          let notify = new Notification("骏工物联提示", {
            // icon: "../../res/img/logo.png",
            body: "您有新的待审核运单",
          });
          // 点击时桌面消息时触发
          notify.onclick = () => {
            // 跳转到当前通知的tab,如果浏览器最小化，会将浏览器显示出来
            window.focus();
          };
        }
      });
    },
    initial() {
      let user_info = this.$my.cache.get("user_info");
      if (this.driver.jb > 0 && user_info.ug_id == 8 || user_info.ug_id ==6) {
        this.message_Popup();
        return;
      }
      if (this.vehicle.jb > 0 && user_info.ug_id == 8 || user_info.ug_id ==6) {
        this.message_Popup();
        return;
      }
      if (this.Waybill.jb > 0 && user_info.ug_id == 8 || user_info.ug_id ==6) {
        this.message_Popup();
        return;
      }
      if (this.bank.jb > 0 && user_info.ug_id == 8|| user_info.ug_id ==6) {
        this.message_Popup();
        return;
      }
    },
    init() {
      console.log("当前版本号1.3.7");
    },
    //菜单展开折叠
    menu_toggle() {
      this.fold = this.fold ? false : true;

      if (this.fold) {
        localStorage.setItem("_pages_index_fold", 1);
      } else {
        localStorage.setItem("_pages_index_fold", 2);
      }
    },

    //菜单初始化
    fold_init() {
      let _pages_index_fold = localStorage.getItem("_pages_index_fold");

      if (_pages_index_fold == 1) {
        this.fold = true;
      } else if (_pages_index_fold == 2) {
        this.fold = false;
      }
    },

    //菜单跳转
    select(index, indexPath) {
      var idx = indexPath[1].split("-");
      var menu = this.menu[idx[0]].children[idx[1]];
      var path = "/pages/" + menu.url.replace("#", "");
      if (path != this.$route.fullPath) {
        this.$router.push({ path: path });
      }
    },

    //退出登录
    logout() {
      this.$my.other.confirm({
        content: "退出登录,请确认?",
        confirm: () => {
          this.$my.net.req({
            data: {
              mod: "admin",
              ctr: "logout",
            },
            callback: (data) => {
              //删除缓存
              this.$my.cache.set({
                is_login:'2',
                user_info: {},
              });

              //删除本地数据
              //sessionStorage.removeItem('session_id');

              //跳转回登录页
              this.$router.push("/login");
            },
          });
        },
      });
    },
    // 角标更新
    jb_update() {
      this.$my.net.req({
        data: {
          mod: "admin",
          ctr: "menu_list_refresh",
        },
        callback: (data) => {
          this.user_info.menu_list = data;
          this.menu_init();
          data.forEach((item, index) => {
            if (item.name == "司机审核") {
              this.driver = item;
            }
            if (item.fname == "风控管理" && item.name == "运单审核") {
              this.Waybill = item;
            }
            if (item.name == "车辆审核") {
              this.vehicle = item;
            }
            if (item.name == "银行卡审核") {
              this.bank = item;
            }
          });
          this.initial();
        },
      });
      // 定时器更新角标
      this.timer = setTimeout(() => {
        this.jb_update();
      }, 300000);
    },
    //菜单初始化
    menu_init() {
      //取出原始菜单列表
      let menu_list = this.user_info.menu_list;
      //格式化主菜单
      let menu_main = {};
      let menu_children = {};
      for (var item of menu_list) {
        if (item.fid == 0) {
          menu_main[item.weight] = {
            id: item.id,
            name: item.name,
            jb: item.jb,
          };
        } else {
          if (!menu_children[item.fid]) menu_children[item.fid] = {};
          menu_children[item.fid][item.weight] = {
            name: item.name,
            url: item.url,
            jb: item.jb,
          };
        }
      }

      //格式化子菜单
      for (var i in menu_main) {
        menu_main[i].children = menu_children[menu_main[i].id];
      }

      //置入数据
      this.menu = menu_main;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
.logo {
  text-align: center;
  padding: 15px 0 0 0;
  img {
    width: 60px;
    padding: 10px 0px;
    cursor: pointer;
  }
}

.main_menu {
  height: 100%;
  overflow-x: hidden;
  background-color: #e0e5e6;

  .menu {
    .group {
      .icon {
      }

      .text {
      }
    }
  }
}

.right_container {
  padding: 0;

  .right_header {
    padding: 0;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: #eee;

    .left {
      cursor: pointer;
      width: 50px;
      text-align: center;
    }
    .right {
      display: flex;
      padding-right: 15px;
      .text {
      }
      .logout_icon {
        cursor: pointer;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  .container {
    background-color: #aaa;
  }
}
#badge {
  margin-top: -20px;
}
</style>